
































































































































































import Vue from 'vue';

import { mapState, mapActions } from 'vuex';

import EditCurrency from './components/EditCurrency.vue';

import { subscriptionActions } from '@/modules/subscription/store/names';

import { ComposedState } from '@/store/general.types';

export default Vue.extend({
    name: 'SubscriptionItemPage',

    components: { EditCurrency },

    data() {
        return {
            dialog: false,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            subscribe: (state: ComposedState) => state.subscription.subscribeData,
        }),
    },

    async mounted() {
        await this.getSubscribeApi(this.$route.params.id);
    },

    methods: {
        ...mapActions({
            deleteSubscribesApi: subscriptionActions.deleteSubscribesApi,
            getSubscribeApi: subscriptionActions.getSubscribeApi,
        }),

        deleteSubscribe() {
            this.dialog = false;

            // @ts-ignore
            this.deleteSubscribesApi(this.subscribe.id);
        },

        closeDialog() {
            this.dialog = false;
        },
    },
});
